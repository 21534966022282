import React from 'react';
import { Document } from '@contentful/rich-text-types';
import { Parallax } from 'react-scroll-parallax';
import FlexibleLink from '../../ui/FlexibleLink';
import Headline from '../../ui/Headline';
import RichText from '../../ui/RichText';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import { ILink } from '../../ui/FlexibleLink/type';
import { IImage } from '../../ui/Image/types';
import Image from '../../ui/Image';
import * as styles from './styles.module.scss';
import FlexibleLinkWrapper from '../../ui/FlexibleLinkWrapper';
import Icon, { IconType } from '../../ui/Icon';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import c from 'classnames';

type ImageContentTeaserProps = {
  headline: string;
  image: IImage;
  text: Document;
  sectionHeadline?: string;
  link?: ILink;
  logoType: 'lieblingsFamilie' | 'lieblingsBar' | 'lucky7Bar' | 'misterQ' | 'none';
  isSmall?: boolean;
  position?: 'Left' | 'Right';
};

let logo: IconType | undefined;

const ImageContentTeaser: React.FC<ImageContentTeaserProps> = ({
  sectionHeadline,
  headline,
  image,
  link,
  text,
  logoType,
  isSmall = false,
  position,
}) => {
  switch (logoType) {
    case 'lieblingsFamilie': {
      logo = 'lieblingsFamilieLogoFull';
      break;
    }
    case 'lieblingsBar': {
      logo = 'lieblingsBarLogoFull';
      break;
    }
    case 'lucky7Bar': {
      logo = 'lucky7LogoFull';
      break;
    }
    case 'misterQ': {
      logo = 'misterQ';
      break;
    }
    case 'none': {
      logo = undefined;
      break;
    }
    default: {
      break;
    }
  }
  const { width } = useWindowDimensions();

  return (
    <>
      {isSmall ? (
        <div className="overflow-x-hidden">
          <div className="teaserAnimation">
            <Container
              additionalClass={c(styles.containerSmall, position === 'Left' ? styles.orderFirst : styles.orderLast)}
            >
              <Row additionalClass="teaserSectionWrapper">
                <Col mobile={12} tablet={6} additionalClass={styles.colClass}>
                  <div className="teaserSection">
                    <FlexibleLinkWrapper link={link}>
                      <Image {...image} additionalClass={styles.img} />
                    </FlexibleLinkWrapper>
                  </div>
                </Col>
                <Col mobile={12} tablet={6} additionalClass={styles.textCol}>
                  <div className="teaserSection">
                    <Parallax y={[-10, 10]} disabled={width < 640}>
                      {headline && (
                        <Headline level={4} additionalClass={styles.subHeadline}>
                          {headline}
                        </Headline>
                      )}
                      {sectionHeadline && (
                        <Headline level={2} additionalClass="desktop:mr-50" color="Black">
                          {sectionHeadline}
                        </Headline>
                      )}
                      <div className={styles.textWrapper}>
                        <RichText content={text} additionalClass={styles.text} />
                      </div>
                      {link && <FlexibleLink link={link} />}
                    </Parallax>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      ) : (
        <Container additionalClass={styles.container}>
          {sectionHeadline && (
            <div className="text-center mb-50 desktop:mb-100 desktop:w-2/3 mx-auto">
              <Headline level={2} color="Black">
                {sectionHeadline}
              </Headline>
            </div>
          )}
          <Row>
            <Col desktop={6} tablet={6} mobile={12} additionalClass={styles.imgCol}>
              <div className={styles.imageGrid}>
                <div className={styles.imageGridLogo}>
                  {logo && <Icon name={logo} additionalClass="fill-current text-white mx-auto" width={250} />}
                </div>
                <div className={styles.imageGridImage}>
                  {link ? (
                    <FlexibleLinkWrapper link={link}>
                      <Image {...image} additionalClass={styles.img} />
                    </FlexibleLinkWrapper>
                  ) : (
                    <Image {...image} additionalClass={styles.img} />
                  )}
                </div>
              </div>
            </Col>
            <Col desktop={6} tablet={6} mobile={12} additionalClass={styles.textCol}>
              <Parallax y={[-10, 10]} disabled={width < 640}>
                <Headline level={2} additionalClass="desktop:mr-50" color="Black">
                  {headline}
                </Headline>
                <div className={styles.textWrapper}>
                  <RichText content={text} additionalClass={styles.text} />
                </div>
                {link && <FlexibleLink link={link} />}
              </Parallax>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ImageContentTeaser;
