// extracted by mini-css-extract-plugin
export var colClass = "styles-module--colClass--3+TpD";
export var container = "styles-module--container--myfYZ";
export var containerSmall = "styles-module--containerSmall--fh21F";
export var imageGrid = "styles-module--imageGrid--aZHnv";
export var imageGridImage = "styles-module--imageGridImage--v0MG6";
export var imageGridLogo = "styles-module--imageGridLogo--BZqv3";
export var img = "styles-module--img--W1JP+";
export var imgCol = "styles-module--imgCol--787DP";
export var orderFirst = "styles-module--orderFirst--8Yuos";
export var orderLast = "styles-module--orderLast--rfHy0";
export var subHeadline = "styles-module--subHeadline--t5oqi";
export var text = "styles-module--text--kwu-p";
export var textCol = "styles-module--textCol--G6q8h";
export var textWrapper = "styles-module--textWrapper--DtI8+";